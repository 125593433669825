<template>
  <ClientOnly>
    <input :id="name" ref="autocompleteLookupInput" :class="className" data-lpignore="true" type="text"
      :placeholder="placeholder" :disabled="disabled" @input="onInput" />
  </ClientOnly>
</template>

<script>
import { computed, ref, nextTick, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useNuxtApp } from '#app'
import { radarAutoCompleteHandler } from '@/utils/radarAutocompleteHandler'
import { convertRadarAddress } from '@/utils/convertRadarAddress'

export default {
  name: 'RadarAddressLookup',
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: 'Enter Your Address'
    },
    name: {
      type: String,
      default: 'autocompleRadarteLookupInput'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    create: {
      type: Boolean,
      default: true
    },
    className: {
      type: String
    }
  },

  emits: ['error', 'created', 'update:modelValue'],

  setup(props, { emit }) {
    const store = useStore()
    const autocompleteLookupInput = ref(null)

    const onInput = (event) => {
      emit('update:modelValue', event.target.value)
    }

    const xFindRegionFromLocationAndZip = store.getters['regions/xFindRegionFromLocationAndZip']
    const xFindLocationFromZip = store.getters['regions/xFindLocationFromZip']
    const xIsAuthenticated = computed(() => store.getters['authuser/xIsAuthenticated'])

    const createFunction = async (convertedAddress) => {
      if (xIsAuthenticated.value) {
        await store.dispatch('authuser/xCreateNewAddress', convertedAddress)
      } else {
        store.dispatch('authuser/xMakeTempAddress', convertedAddress)
      }
    }

    const handlePlaceSelected = async (place) => {
      console.log('Place selected:', place)

      if (!place || !place.postalCode) {
        console.error('Invalid place object or missing postal code')
        emit('error')
        return
      }

      const location = xFindLocationFromZip(place.postalCode)
      console.log('Location found:', location)

      if (!location) {
        console.error('No location found for postal code:', place.postalCode)
        emit('error')
        return
      }

      console.log('Calling xFindRegionFromLocationAndZip with:', location, place.postalCode)
      const region = xFindRegionFromLocationAndZip(location, place.postalCode, false)
      console.log('Region found:', region)

      if (!region) {
        console.error('No region found for location and postal code')
        emit('error')
        return
      }

      let convertedAddress = null
      if (place.postalCode && region && location) {

        console.log(location, 'locationRadar')

        convertedAddress = {
          address: place?.addressLabel ? place.addressLabel : '',
          address_2: null,
          address_id: location.address_id || "temp",
          city: place?.city,
          state: place?.stateCode,
          zip: place?.postalCode,
          region: region.name,
          location_id: location.id
        }
      } else {
        console.error('error making wp address', { place, region, location })
        return
      }

      console.log('Converted address:', convertedAddress)

      if (!convertedAddress) {
        console.error('Failed to convert address')
        emit('error')
        return
      }

      if (props.create) {
        await createFunction(convertedAddress)
      }

      emit('created', convertedAddress)
      emit('shop-place', convertedAddress) // 👈 Add this line
      emit('update:modelValue', convertedAddress)
    }

    onMounted(() => {
      const nuxtApp = useNuxtApp()
      const radar = nuxtApp.$radar
      nextTick(() => {
        if (autocompleteLookupInput.value) {
          radarAutoCompleteHandler(radar, autocompleteLookupInput.value, handlePlaceSelected, {
            container: props.name,
            limit: 8
          })
        }
      })
    })

    return {
      autocompleteLookupInput,
      onInput
    }
  }
}
</script>

<style>
#radar-autocomplete-results-list {
  max-height: 200px;
  overflow-y: auto;
  z-index: 999;
}

.radar-autocomplete-wrapper {
  background: white;
}

.radar-autocomplete-results-item {
  margin-top: 1%;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #e9e9e9;
  gap: 5px;
}

.radar-autocomplete-results-item:hover {
  cursor: pointer;
}

.radar-powered {
  margin-top: 1%;
}
</style>
